@import '../../assets/styles/variables.scss';

.flex {
  display: flex;
  justify-content: space-between;
}

.logs-container {
  background-color: $bg-tab-content;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  color: $color-white;
  display: flex;
  flex-direction: column;
  padding: 30px 0 0;
  margin-bottom: 40px;

  .filters-bar {
    @extend .flex;
    flex-direction: column;
    padding: 0 25px;
    width: 100%;

    &-main {
      @extend .flex;
      color: $color-grey;
      font-family: 'OpenSans', 'Helvetica Neue', sans-serif;
      flex-direction: row;

      .search-field {
        @extend .flex;
        padding-top: 6px;
      }

      .date-range {
        align-items: flex-start;
        background-color: $bg-tab;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        padding: 12px 25px 20px;
        position: relative;

        .date-field {
          width: 170px;
        }

        .form-field {
          &.no-label {
            padding-top: 25px;
          }

          label {
            margin-bottom: 6px;
          }
        }

        .btn-reset {
          margin-left: 12px;
        }
      }

      .count {
        @extend .flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 12px;
        margin-left: 12px;
        min-height: 32px;
        min-width: 70px;
      }
    }

    .form-field {
      display: flex;
      flex-direction: column;

      label {
        color: $color-white;
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.42;
        margin-bottom: 12px;
      }
    }

    .btn-reset {
      color: $color-grey;
      display: block;
      font-size: 12px;
      padding: 0;

      span {
        text-decoration: underline;
      }
    }

    .btn-search {
      align-items: center;
      border-radius: 0;
      display: flex;

      svg {
        margin-right: 8px;
      }
    }
  }

  .row {
    @extend .flex;
    margin-bottom: $spacer-30;
  }

  > .row {
    margin-bottom: $spacer-30;
  }

  .block-right {
    margin-left: auto;
  }

  .block-left {
    margin-right: auto;
  }
}
