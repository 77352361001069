@import '../../assets/styles/variables.scss';

.date-picker-container {
  align-items: center;
  display: flex;
  padding-right: 10px;
  position: relative;

  .ant-picker {
    border-radius: 0;
    padding: 0 10px;
    width: 100%;

    .ant-picker-input {
      > input {
        background: transparent;
        color: #fff;
        font-family: inherit;
        font-size: 12px;
      }
    }

    &.outline {
      background: transparent;
      .ant-picker-input {
        > input {
          padding: 6px 0;
        }
      }
    }
  }
}
