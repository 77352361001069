@import '../../assets/styles/variables.scss';

.container.not-found {
  align-items: flex-start;
  background-color: $bg-grey-light;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;

  &:after {
    content: '';
    background: url('../../assets/images/bg/bg_not_found.png') no-repeat left center;
    background-size: 200%;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    z-index: 0;
  }

  .panel {
    display: flex;
    flex-direction: column;
    margin: 15vh 0 0 10vw;
    position: relative;
    width: 380px;
    z-index: 1;

    .row {
      margin-bottom: 20px;
    }

    .logo {
      fill: #0a6eaf;
    }

    .message {
      color: $color-blue-dark;
      font-family: 'OpenSans', 'Helvetica Neue', sans-serif;
      font-size: 46px;
      font-weight: 600;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      margin-top: 90px;
    }

    .ant-btn {
      align-items: center;
      display: flex;
      justify-content: center;
      font-family: 'OpenSans', 'Helvetica Neue', sans-serif;

      &.ant-btn-primary {
        background: $color-blue-secondary;
        border-color: $color-blue-secondary;
        font-size: 18px;
        height: 52px;
        padding: 18px 60px;
      }
    }
  }
}
