.maintenance-wrapper {
  width: 100%;
  padding: 10px 24px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  background: #689e32;
  color: #ffffff;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  gap: 10px;
  z-index: 10;
  align-items: center;

  img {
    width: 35px;
    height: 33.04px;
  }
  .close-button {
    cursor: pointer;
  }
}
