// Backgrounds
$bg-page: #2a2d34;
$bg-tab-content: #1e1f24;
$bg-tab: #3f424e;
$bg-grey-light: #e6e6e6;
$bg-blue: #46799a;

//Borders
$color-border-grey: #979797;
$color-input-border-light: #c5d3e2;
$color-separator-grey: #5a5e6f;

// Colors
$color-blue-primary: #3a89f7;
$color-blue-secondary: #0a6eb4;
$color-blue-dark: #103146;
$color-black: #000;
$color-white: #fff;
$color-green: #417505;
$color-grey: #98a6b5;
$color-grey-light: #c5d3e2;

// Input placeholder
$color-placeholder-grey: #98a6b5;

// Margins and Paddings
$spacer-xs: 4px;
$spacer-s: 8px;
$spacer-m: 12px;
$spacer-l: 18px;
$spacer-xl: 20px;
$spacer-25: 25px;
$spacer-30: 30px;
$spacer-40: 40px;
$spacer-50: 50px;
