.ant-table-wrapper {
  &.loading {
    .ant-table-placeholder {
      .ant-empty {
        visibility: hidden;
      }
    }
  }

  .ant-table {
    background: transparent !important;

    .ant-table-content {
      .ant-table-thead {
        tr {
          &:first-child {
            border-radius: 0;
          }

          th {
            background: transparent;
            border-bottom: 1px solid #3f424e;
            color: #98a6b5;
            font-size: 12px;
            line-height: 1.3;
            padding: 6px 8px;
            vertical-align: middle;

            &:first-child {
              border-radius: 0;
              padding-left: 15px;
            }

            &.ant-table-cell-ellipsis {
              white-space: inherit;
            }

            &.ant-table-row-expand-icon-cell {
              padding: 4px;
              width: 17px;
            }

            .ant-table-column-sorters {
              padding: 0;
            }
          }
        }
      }

      .ant-table-tbody {
        tr {
          &:nth-child(even) {
            background-color: #5a5e6f;
          }

          &:nth-child(odd) {
            background-color: #3f424e;
          }

          &.ant-table-placeholder {
            &:hover > td {
              background: none;
            }
            .ant-empty {
              color: #98a6b5;
            }
          }

          &.ant-table-row {
            &:hover {
              > td {
                background: none;
              }
            }
          }

          td {
            border: none;
            color: #fff;
            font-size: 13px;
            padding: 6px 8px;

            &:first-child {
              padding-left: 15px;
            }

            &.ant-table-row-expand-icon-cell {
              width: 17px;
              padding-left: 5px;

              .ant-table-row-expand-icon {
                float: right;
              }
            }

            &.ant-table-column-sort {
              background: none;
            }

            .cell-ellipsis {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .cell-status {
              color: #98a6b5;
              width: 95px;
            }

            .not-processed,
            .notconfirmed,
            .processed,
            .error,
            .rejected {
              &:before {
                border-radius: 50px;
                content: '';
                display: inline-block;
                height: 14px;
                width: 14px;
                margin-right: 5px;
                position: relative;
                top: 1px;
              }
            }

            .not-processed,
            .notconfirmed {
              &:before {
                border: 1px solid #fff;
              }
            }
            .processed {
              &:before {
                background-color: #417505;
              }
            }
            .error,
            .rejected {
              &:before {
                background-color: #f5a623;
              }
            }
          }

          .ant-table-row-expand-icon {
            background: #1890ff;
            position: relative;
            top: 2px;

            &:hover {
              background: #1890ff;
            }
          }

          &.ant-table-expanded-row {
            &:hover {
              td {
                background: #1890ff60;
              }
            }
            > td {
              padding: 0;
            }
            td {
              background: #1890ff60;
              .ant-table {
                margin: 0;

                .ant-table-thead {
                  display: none;
                }

                .ant-table-tbody {
                  td {
                    &:first-child {
                      padding-left: 25px;
                    }
                  }
                }
              }
            }
          }
        }

        .btn-small {
          width: 70px;

          &.btn-save {
            background: #417505;
            border-color: #417505;
          }

          &.btn-edit {
            background: transparent;
            border-color: #c5d3e2;
          }
        }

        .editable-row {
          .actions {
            position: relative;

            .btn-cancel {
              position: absolute;
              top: 0;
              right: -90px;
              height: 24px;
              width: 70px;
            }
          }

          .ant-form-item-explain {
            position: absolute;
            top: 100%;
            font-size: 12px;
          }
        }
      }
    }
  }
}
