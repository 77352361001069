.landing-page-container {
  align-items: center;
  background: url('../../assets/images/bg/lp_bg_2.jpg') no-repeat center top;
  background-size: cover;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &:after {
    content: '';
    background-color: #000;
    opacity: 0.6;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  .lp-panel {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    font-family: 'OpenSans', 'Helvetica Neue', sans-serif;
    margin-top: -20%;
    overflow: hidden;
    position: relative;
    width: 465px;
    z-index: 9;

    &-title {
      align-items: center;
      background-color: #0288ff;
      color: #fff;
      display: flex;
      justify-content: center;
      font-size: 20px;
      letter-spacing: 0.56px;
      line-height: 1.35;
      padding: 10px 0;

      &:before {
        content: '';
        background: url('../../assets/svg/logo.svg') no-repeat;
        background-size: 130px;
        display: inline-block;
        height: 35px;
        margin-right: 25px;
        width: 40px;
      }
    }

    &-content {
      padding: 20px;

      hr {
        margin: 20px 0;
      }

      .row {
        justify-content: space-between;
      }

      .ant-btn {
        align-items: center;
        display: flex;
        justify-content: center;
        font-family: 'OpenSans', 'Helvetica Neue', sans-serif;

        &.ant-btn-primary {
          background: #417505;
          border-color: #417505;

          svg {
            height: 24px;
            width: 24px;
          }
        }

        &.ant-btn-default {
          background: #296755;
          border-color: #296755;
          color: #fff;
          max-width: 200px;

          svg {
            height: 20px;
            width: 20px;
          }
        }

        svg {
          fill: #fff;
          margin-right: 12px;
        }
      }
    }
  }
}
