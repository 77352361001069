@import '../../assets/styles/variables.scss';

.flex {
  display: flex;
  justify-content: space-between;
}

.invoices-container {
  background-color: $bg-tab-content;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  color: $color-white;
  display: flex;
  flex-direction: column;
  padding: 30px 0 0;
  margin-bottom: 40px;

  .filters-bar {
    @extend .flex;
    flex-direction: column;
    padding: 0 25px;
    width: 100%;

    &-main {
      @extend .flex;
      color: $color-grey;
      font-family: 'OpenSans', 'Helvetica Neue', sans-serif;
      flex-direction: row;

      .search-field {
        @extend .flex;
        padding-top: 6px;
      }

      .date-range {
        align-items: flex-start;
        background-color: $bg-tab;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        padding: 12px 25px 20px;
        position: relative;

        &.advanced-filters-is-open {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          .show-more-filters-icon {
            transform: rotate(180deg);
          }
        }

        .date-field {
          width: 170px;
        }

        .form-field {
          &.no-label {
            padding-top: 25px;
          }

          label {
            margin-bottom: 6px;
          }
        }

        .show-more-filters {
          align-items: center;
          color: $color-white;
          cursor: pointer;
          display: flex;
          font-size: 13px;
          padding: 0 25px 8px;
          position: absolute;
          bottom: 0;
          left: 0;
          text-align: right;
          width: 100%;

          &:before {
            content: '';
            flex: 1;
            border-bottom: 1px solid $color-separator-grey;
            margin-right: 16px;
          }

          &-icon {
            margin-left: 8px;
            height: 16px;
            width: 16px;
            svg {
              fill: $color-grey-light;
              height: 100%;
              width: 100%;
            }
          }
        }
      }

      .count {
        @extend .flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 12px;
        margin-left: 12px;
        min-height: 32px;
        min-width: 70px;
      }
    }

    &-advanced {
      @extend .flex;
      align-items: flex-start;
      background-color: $bg-tab;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: 10px;
      flex-direction: column;
      padding: 14px 24px 0;
      width: 100%;

      &-control {
        justify-content: flex-end;
      }
    }

    .form-field {
      display: flex;
      flex-direction: column;

      label {
        color: $color-white;
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.42;
        margin-bottom: 12px;
      }
    }

    .btn-reset {
      color: $color-grey;
      display: block;
      font-size: 12px;
      padding: 0;

      span {
        text-decoration: underline;
      }
    }

    .btn-search {
      align-items: center;
      border-radius: 0;
      display: flex;

      svg {
        margin-right: 8px;
      }
    }
  }

  .row {
    @extend .flex;
    align-items: flex-start;
    width: 100%;
  }

  > .row {
    margin-bottom: $spacer-30;
  }

  .block-right {
    margin-left: auto;
  }
}
