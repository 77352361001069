.navigation {
  background-color: transparent;
  display: flex;
  height: 50px;
  padding-left: 100px;

  .item {
    background-color: #3a89f7;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 12px;
    margin-left: 18px;

    &:first-child {
      background-color: #000;
      padding: 0 18px;
    }

    .item-icon {
      height: 23px;
      width: 24px;
    }

    .item-text {
      color: #fff;
      font-size: 20px;
    }
  }
}
