@import '../../assets/styles/variables.scss';

.container.forbidden {
  align-items: flex-start;
  background-color: $color-blue-secondary;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;

  .panel {
    display: flex;
    flex-direction: column;
    margin: 15vh 0 0 10vw;
    position: relative;
    max-width: 650px;
    z-index: 1;

    .row {
      margin-bottom: 20px;
    }

    .logo {
      fill: $color-white;
    }

    .message {
      color: $color-white;
      font-family: 'OpenSans', 'Helvetica Neue', sans-serif;
      font-size: 28px;
      font-weight: 600;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;

      p {
        font-size: 24px;
        margin-bottom: 15px;
      }

      a {
        color: $color-white;
        text-decoration: underline;
      }
    }

    .ant-btn {
      align-items: center;
      display: flex;
      justify-content: center;
      font-family: 'OpenSans', 'Helvetica Neue', sans-serif;

      &.ant-btn {
        background: transparent;
        border-color: $color-white;
        color: $color-white;
        font-size: 18px;
        height: 52px;
        padding: 18px 60px;
      }
    }
  }
}
