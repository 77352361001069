@import '../../assets/styles/variables.scss';

.search-input-container {
  align-items: center;
  background-color: transparent;
  border: 1px solid $color-input-border-light;
  display: flex;
  height: 32px;

  .search-input {
    background-color: transparent;
    border: none;
    color: $color-white;
    display: flex;
    flex: 1;
    font-family: inherit;
    font-size: 12px;
    padding: 0.33em 0.65em;

    &::placeholder {
      color: $color-placeholder-grey;
    }

    &:focus {
      outline: none;
    }
  }

  .search-input-btn {
    align-items: center;
    background-color: $color-blue-primary;
    border-left: 1px solid $color-input-border-light;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 45px;

    svg {
      height: 16px;
      width: 16px;
    }
  }
}
