@import '../../assets/styles/variables.scss';
@import '../../assets/styles/tables.scss';

.page {
  background-color: $bg-page;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: 'OpenSans', 'Helvetica Neue', sans-serif;

  &-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 30px auto 0;
    width: 1120px;
  }

  &-title {
    border-bottom: 1px solid $color-border-grey;
    color: $color-white;
    font-size: 30px;
    font-weight: normal;
    line-height: 1.37;
    padding: 0 0 16px;
  }

  .content {
    margin-top: 30px;
  }
}
