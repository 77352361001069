@import '../../assets/styles/variables.scss';

.tabs {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .tab,
  .tab-active {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    cursor: pointer;
    font-family: 'OpenSans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    margin-right: 4px;
    min-width: 230px;
    padding: 8px 0;
    text-align: center;
  }

  .tab {
    background-color: $bg-tab;
    color: #86929f;
  }

  .tab-active {
    background-color: $bg-tab-content;
    color: #fff;
  }
}
