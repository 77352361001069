:root {
  --amplify-background-color: transparent;
  --amplify-primary-color: #417505;
  --amplify-primary-tint: #4b8806;
  --amplify-primary-shade: #417505;
  --amplify-secondary-color: #ddd;
  --amplify-font-family: 'OpenSans', 'Helvetica Neue', sans-serif;
  --amplify-text-md-sub: 26px;
}

amplify-authenticator {
  align-items: center;
  background: url('../../assets/images/bg/lp_bg_2.jpg') no-repeat center top;
  background-size: cover;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;
  --border-radius: 10px;
  --margin-bottom: 0;

  &:after {
    content: '';
    background-color: $color-black;
    opacity: 0.6;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  amplify-sign-in {
    background: url('../../assets/images/bg/login_mask.png') no-repeat left top;
    background-color: $bg-page;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
    margin-top: -10%;
    min-height: 240px;
    min-width: 435px;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
}
