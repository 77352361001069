@import '../../assets/styles/variables.scss';

.login-container {
  align-items: center;
  background: url('../../assets/images/bg/lp_bg_2.jpg') no-repeat center top;
  background-size: cover;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &:after {
    content: '';
    background-color: $color-black;
    opacity: 0.6;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  .login-panel {
    align-items: center;
    background: url('../../assets/images/bg/login_mask.png') no-repeat left top;
    background-color: $bg-page;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    margin-top: -10%;
    min-height: 240px;
    overflow: hidden;
    padding: 38px 90px 32px;
    position: relative;
    width: 435px;
    z-index: 1;

    .row {
      justify-content: space-between;
    }

    .login-greeting {
      color: $color-white;
      font-family: 'OpenSans', 'Helvetica Neue', sans-serif;
      font-size: 30px;
      font-weight: 300;
      line-height: 1.37;
      letter-spacing: normal;
      text-align: center;

      strong {
        font-weight: 700;
      }
    }

    .ant-btn {
      align-items: center;
      display: flex;
      justify-content: center;
      font-family: 'OpenSans', 'Helvetica Neue', sans-serif;

      &.ant-btn-primary {
        background: $color-green;
        border-color: $color-green;
      }
    }
  }
}
