@import '../../assets/styles/variables.scss';

.input-container {
  align-items: center;
  background-color: transparent;
  display: flex;
  height: auto;

  &.material {
    border-bottom: 1px solid rgba(197, 211, 226, 0.7);
    height: 20px;
  }

  &.outline {
    border: 1px solid $color-input-border-light;
    height: 30px;
    padding: 0.33em 0.55em;
  }

  .input-field {
    background-color: transparent;
    border: none;
    color: $color-white;
    display: flex;
    flex: 1;
    font-family: inherit;
    font-size: 12px;

    &::placeholder {
      color: $color-placeholder-grey;
    }

    &:focus {
      outline: none;
    }
  }
}
