@import '../../assets/styles/variables.scss';

.ant-select {
  .ant-select-selector {
    padding: 0 4px;
  }

  &.outline {
    align-items: center;
    color: $color-white;
    display: flex;
    font-size: 12px;

    &:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent;
      border: 1px solid $color-input-border-light;
      border-radius: 0;
      height: 30px;

      .ant-select-selection-placeholder {
        color: $color-grey-light;
      }
    }

    .ant-select-arrow {
      margin-top: -8px;
      right: 8px;
      top: 50%;
      height: 16px;
      width: 16px;

      svg {
        fill: $color-grey-light;
        height: 100%;
        width: 100%;
      }
    }
  }

  &.ant-select-multiple {
    &:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
      height: auto !important;
      min-height: 30px;
      padding: 0 4px;

      .ant-select-selection-item {
        background: transparent;

        svg {
          fill: $color-white;
        }
      }
    }
  }
}
