@import '../../assets/styles/variables.scss';

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.pagination {
  @extend .flex-center;

  &-pages {
    @extend .flex-center;
    border: 1px solid $color-grey-light;
    border-radius: 4px;
    margin-right: 8px;
    overflow: hidden;
  }

  &-nav {
    @extend .flex-center;
  }

  &-item {
    @extend .flex-center;
    background-color: $bg-tab;
    border-right: 1px solid $color-grey-light;
    color: $color-grey-light;
    cursor: pointer;
    font-family: inherit;
    font-size: 12px;
    height: 24px;
    min-width: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:last-child {
      border-right: none;
    }

    &-active {
      background-color: $color-blue-secondary;
    }

    &-disabled {
      opacity: 0.5;
    }

    &-prev {
      border-radius: 4px 0 0 4px;
    }

    &-next {
      border-radius: 0 4px 4px 0;
    }

    > svg {
      fill: $color-grey-light;
      height: 9px;
      width: 6px;
    }
  }

  .pagination-text {
    color: $color-grey-light;
    font-family: inherit;
    font-size: 12px;
    margin-right: 8px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: $bg-tab;
    border-radius: 4px;
    border: 1px solid $color-grey-light;
    color: $color-grey-light;
    height: 26px;
    display: flex;
    align-items: center;
  }

  .ant-select-arrow {
    color: $color-white;
    top: 50%;

    > svg {
      fill: $color-grey-light;
      height: 6px;
      width: 9px;
    }
  }
}
