@import '../../assets/styles/variables.scss';

.ant-pagination {
  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    background-color: $bg-tab;
    border: 1px solid $color-grey-light;
    border-right: none;
    border-radius: 0;
    font-family: inherit;
    font-size: 12px;
    height: 24px;
    margin: 0;
    min-width: 24px;
    line-height: 24px;

    &:hover {
      background-color: $color-blue-secondary;
      border-color: $color-grey-light;

      a {
        color: $color-grey-light;
      }
    }

    a {
      color: $color-grey-light;
    }

    button.ant-pagination-item-link {
      background-color: $bg-tab;
      border: none;
      color: $color-grey-light;
    }
  }

  .ant-pagination-prev {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .ant-pagination-next {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right: 1px solid $color-grey-light;
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    .ant-pagination-item-container {
      .ant-pagination-item-ellipsis,
      .ant-pagination-item-link-icon {
        color: $color-grey-light;
      }
    }
  }

  .ant-pagination-item-active {
    background-color: $color-blue-secondary;
    border-color: $color-grey-light;

    a {
      color: $color-grey-light;
    }
  }

  .ant-pagination-options {
    .ant-select {
      font-size: 12px;

      &:not(.ant-select-customize-input) .ant-select-selector {
        background-color: $bg-tab;
        border: 1px solid $color-grey-light;
        color: $color-grey-light;
        height: 24px;
        &:after {
          line-height: 24px;
        }
      }

      &.ant-select-single {
        .ant-select-selector {
          padding: 0 8px;

          .ant-select-selection-search {
            input {
              height: 24px;
            }
          }

          .ant-select-selection-item,
          .ant-select-selection-placeholder {
            line-height: 24px;
          }
        }
      }

      .ant-select-arrow {
        color: $color-grey-light;
      }
    }
  }

  &.ant-pagination-disabled {
    opacity: 0.5;
    .ant-pagination-item {
      background: $bg-tab !important;
      border-color: $color-grey-light !important;
    }
  }
}
