@import '../../assets/styles/variables.scss';

.guesthouse-container {
  background-color: $bg-tab-content;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  color: $color-white;
  display: flex;
  flex-direction: column;
  padding: 30px 0 0;
  margin-bottom: 40px;

  .filters-bar {
    color: $color-grey;
    font-family: 'OpenSans', 'Helvetica Neue', sans-serif;
    padding: 0 50px 0 40px;

    &-title {
      color: $color-white;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.42;
      margin-bottom: 15px;
    }

    &-search {
      display: inline-block;
      margin-right: 15px;
    }

    &-results {
      font-size: 12px;
      margin-left: auto;
    }

    &-btn-reset {
      color: $color-grey;
      font-size: 12px;
      padding: 0;

      span {
        text-decoration: underline;
      }
    }
  }

  .row {
    justify-content: space-between;
    margin-bottom: $spacer-30;
  }

  .update-info {
    color: $color-grey;
    font-family: inherit;
    font-size: 11px;
    line-height: 1.4;

    > div:last-child {
      font-size: 14px;
    }
  }
}
